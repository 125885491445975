<template>
    <localization :language="'ru-RU'">
        <intl :locale="'ru'">
            <div class="dealers-page">
                <CRow class="table-actions-row">
                    <v-col sm="12" cols="12" lg="4" class="pl-0">
                        <breadcrumbs :items="crumbs" />
                    </v-col>
                    <v-col
                        sm="12"
                        md="12"
                        lg="8"
                        class="action-btns d-flex justify-end align-end flex-column flex-md-row"
                    >
                        <CButton
                            @click="downloadDealerBalance(balanceDate)"
                            :disabled="!balanceDate"
                            class="add-product-modal mr-lg-3"
                            color="success"
                        >
                            <i class="la la-download"></i>
                            Выгрузить баланс
                        </CButton>

                        <datepicker
                            class="mr-lg-3 mt-3 mt-md-0"
                            v-model="balanceDate"
                            :calendar-type="'infinite'"
                            :format="'dd.MM.yyyy'"
                        ></datepicker>

                        <CButton
                            @click="
                                clearCurrentItem()
                                showEntityModal = true
                            "
                            class="add-product-modal mt-3 mt-md-0"
                            color="info"
                        >
                            <i class="la la-plus"></i>
                            Добавить дилера
                        </CButton>
                        <CButton
                            @click="showBonusFileModal = true"
                            class="add-product-modal toolbox-btn toolbox-btn-green ml-3 mt-3 mt-md-0"
                        >
                            <i class="la la-plus"></i>
                            Загрузить файл с бонусами
                        </CButton>
                    </v-col>
                </CRow>
                <Grid
                    ref="grid"
                    class="dealers"
                    :resizable="true"
                    :sortable="true"
                    :filterable="true"
                    :pageable="gridPageable"
                    :filter="filter"
                    @datastatechange="dataStateChange"
                    :take="take"
                    :skip="skip"
                    :sort="sort"
                    :page-size="pageSize"
                    :total="total"
                    :data-items="dataResult"
                    :columns="columns"
                    :expand-field="'expanded'"
                    :detail="detailTemplate"
                    @expandchange="expandChange"
                    @sortchange="sortChange"
                >
                    <template v-slot:actions="{ props: { dataItem } }">
                        <td>
                            <div class="actionsColumn">
                                <actions-dropdown
                                    :actions="[
                                        {
                                            text: dataItem.is_blocked
                                                ? 'Разблокировать'
                                                : 'Заблокировать',
                                            icon:
                                                'fa ' +
                                                (dataItem.is_blocked
                                                    ? 'fa-unlock'
                                                    : 'fa-lock'),
                                            handler: () =>
                                                toggleBlocked(dataItem),
                                        },
                                        {
                                            text: 'Редактировать',
                                            icon: 'la la-pencil',
                                            handler: () => {
                                                setCurrentItem(dataItem)
                                                showEntityModal = true
                                            },
                                        },
                                        {
                                            text: 'Сбросить пароль',
                                            icon: 'fa fa-key',
                                            handler: () =>
                                                resetPassword(dataItem),
                                        },
                                        {
                                            text: 'Удалить',
                                            icon: 'la la-trash',
                                            handler: () =>
                                                deleteEntity(dataItem),
                                        },
                                        {
                                            text: 'Войти под дилером',
                                            icon: 'fa fa-sign-in',
                                            handler: () => loginAs(dataItem),
                                        },
                                    ]"
                                />
                            </div>
                        </td>
                    </template>
                    <template v-slot:blocked_filter="{ methods, props }">
                        <boolean-filter
                            @change="
                                selectFilter('is_blocked', $event.value.value)
                            "
                        />
                    </template>
                    <template v-slot:blocked="{ props: { dataItem } }">
                        <td>
                            <div>
                                <span
                                    class="m-badge m-badge--wide m-badge--wide"
                                    :class="{
                                        'm-badge--success': dataItem.is_blocked,
                                        'm-badge--danger': !dataItem.is_blocked,
                                    }"
                                >
                                    {{ dataItem.is_blocked ? 'ДА' : 'НЕТ' }}
                                </span>
                            </div>
                        </td>
                    </template>
                    <template v-slot:detail="{ props: { dataItem } }">
                        <div class="detail-wrapper dealer-detail">
                            <table class="table table-striped m-table">
                                <tbody>
                                    <tr style="visibility: hidden">
                                        <td width="200px"></td>
                                        <td></td>
                                    </tr>
                                    <tr>
                                        <td class="table-heading" colspan="2">
                                            <b>Профиль</b>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td width="200px">E-mail:</td>
                                        <td>
                                            <p>{{ dataItem.email }}</p>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>Наименомание:</td>
                                        <td>
                                            <p>{{ dataItem.name }}</p>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>КССС:</td>
                                        <td>
                                            <p>{{ dataItem.unique_id }}</p>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>Заблокирован?:</td>
                                        <td>
                                            <span
                                                class="m-badge m-badge--wide m-badge--wide"
                                                :class="{
                                                    'm-badge--success':
                                                        dataItem.is_blocked,
                                                    'm-badge--danger':
                                                        !dataItem.is_blocked,
                                                }"
                                            >
                                                {{
                                                    dataItem.is_blocked
                                                        ? 'ДА'
                                                        : 'НЕТ'
                                                }}
                                            </span>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>Баланс:</td>
                                        <td>
                                            <p>{{ dataItem.points_number }}</p>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                            <table class="table table-striped m-table">
                                <tbody>
                                    <tr style="visibility: hidden">
                                        <td width="200px"></td>
                                        <td></td>
                                    </tr>

                                    <tr>
                                        <td class="table-heading" colspan="2">
                                            <b>Договор с агентством</b>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>Номер:</td>
                                        <td>
                                            {{
                                                dataItem.contract_agency_number
                                                    ? dataItem.contract_agency_number
                                                    : '-'
                                            }}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>Дата:</td>
                                        <td>
                                            {{
                                                dataItem.contract_agency_date
                                                    ? moment(
                                                          dataItem.contract_agency_date
                                                      ).format('DD.MM.YYYY')
                                                    : '-'
                                            }}
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                            <table class="table table-striped m-table">
                                <tbody>
                                    <tr style="visibility: hidden">
                                        <td width="200px"></td>
                                        <td></td>
                                    </tr>
                                    <tr>
                                        <td class="table-heading" colspan="2">
                                            <b>Контактное лицо</b>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>ФИО:</td>
                                        <td>
                                            <p>
                                                {{
                                                    dataItem.contact_person_name
                                                }}
                                            </p>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>Должность:</td>
                                        <td>
                                            <p>
                                                {{
                                                    dataItem.contact_person_position
                                                }}
                                            </p>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>Телефон:</td>
                                        <td>
                                            <p>
                                                {{
                                                    dataItem.contact_person_phone
                                                }}
                                            </p>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </template>
                </Grid>
                <dealer-modal
                    v-if="showEntityModal"
                    :current-item="currentItem"
                    :show-dialog="showEntityModal"
                    @click:outside="showEntityModal = false"
                    @close="showEntityModal = false"
                    @stored="entityStored($event)"
                />
                <bonus-file-modal
                    v-if="showBonusFileModal"
                    :current-item="currentItem"
                    :show-dialog="showBonusFileModal"
                    @close="showBonusFileModal = false"
                    @stored="
                        showBonusFileModal = false
                        updateTable()
                    "
                />
            </div>
        </intl>
    </localization>
</template>

<script>
    import { Grid } from '@progress/kendo-vue-grid'
    import {
        IntlProvider,
        LocalizationProvider,
    } from '@progress/kendo-vue-intl'
    import ActionsDropdown from '@/views/grid_elements/ActionsDropdown'
    import { showConfirm } from '@/lib/swal2'
    import DealerCreateUpdateModal from './modals/DealerCreateUpdateModal'
    import UploadBonusFileModal from './modals/UploadBonusFileModal'
    import '@/views/grid_elements/translations'
    import api from '@/api'
    import { showMessage } from '@/lib/toasted'
    import TableMixin from '@/views/grid_elements/mixins/TableMixin'
    import ResizableTableMixin from '@/views/grid_elements/mixins/ResizableTableMixin'
    import Breadcrumbs from '@/views/components/breadcrumbs'
    import BooleanFilter from '@/views/components/BooleanFilter'
    import { DatePicker } from '@progress/kendo-vue-dateinputs'
    import { mapActions } from 'vuex'
    import { toDataSourceRequestString } from '@progress/kendo-data-query'

    export default {
        name: 'dealers',
        components: {
            Grid: Grid,
            ActionsDropdown,
            BooleanFilter,
            breadcrumbs: Breadcrumbs,
            'dealer-modal': DealerCreateUpdateModal,
            'bonus-file-modal': UploadBonusFileModal,
            localization: LocalizationProvider,
            intl: IntlProvider,
            datepicker: DatePicker,
        },
        mixins: [TableMixin, ResizableTableMixin],
        mounted() {
            this.getData()
        },
        data() {
            return {
                skip: 0,
                take: 10,
                pageSize: 10,
                total: 7,
                gridPageable: { pageSizes: true },
                detailTemplate: 'detail',
                expandedItems: [],
                columns: [
                    {
                        field: 'id',
                        title: 'Действия',
                        cell: 'actions',
                        filterable: false,
                        sortable: false,
                    },
                    {
                        field: 'name',
                        title: 'Наименование',
                    },
                    {
                        field: 'unique_id',
                        title: 'КССС',
                    },
                    {
                        field: 'points_number',
                        title: 'Баланс',
                    },
                    {
                        field: 'contract_agency_number',
                        title: 'Номер договора',
                    },
                    {
                        field: 'contract_agency_date',
                        title: 'Дата договора',
                        filter: 'date',
                        format: '{0:d}',
                        type: 'date',
                    },
                    {
                        field: 'is_blocked',
                        title: 'Заблокирован?',
                        filterCell: 'blocked_filter',
                        cell: 'blocked',
                        filter: 'boolean',
                    },
                    {
                        field: 'last_order_date',
                        title: 'Дата последнего заказа',
                        filter: 'date',
                        format: '{0:d}',
                        type: 'date',
                    },
                ],
                showEntityModal: false,
                showBonusFileModal: false,
                currentItem: null,
                balanceDate: new Date(),
            }
        },
        methods: {
            fetchData: api.dealers.getDealers,
            ...mapActions('account', ['loginAsDealer']),
            async deleteEntity(item) {
                const { isConfirmed } = await showConfirm(
                    'Вы действительно хотите удалить данный элемент?'
                )

                if (isConfirmed) {
                    const {
                        data: { status, message },
                    } = await api.dealers.deleteDealer(item.id)
                    showMessage(status, message)

                    await this.getData()
                }
            },
            prepareFetchParams() {
                return [
                    this.page,
                    this.take,
                    toDataSourceRequestString({
                        filter: this.filter,
                        sort: this.sort,
                    }),
                ]
            },
            async toggleBlocked(item) {
                const { is_blocked, id } = item
                const message = is_blocked
                    ? 'Вы действительно хотите разблокировать данного дилера?'
                    : 'Вы действительно хотите заблокировать данного дилера?'
                const { isConfirmed } = await showConfirm(message)

                if (isConfirmed) {
                    const {
                        data: { status, message },
                    } = await api.dealers.toggleDealerProperty(id, 'is-blocked')
                    showMessage(status, message)

                    await this.getData()
                }
            },
            async resetPassword(item) {
                const { id } = item
                const message =
                    'Вы действительно хотите сгенерировать новый пароль и отправить его на e-mail дилера?'
                const { isConfirmed } = await showConfirm(message)

                if (isConfirmed) {
                    const {
                        data: { status, message },
                    } = await api.dealers.resetDealerPassword(id)
                    showMessage(status, message)
                }
            },
            async loginAs(item) {
                const { id } = item
                const message =
                    'Вы действительно хотите авторизоваться под дилером?'
                const { isConfirmed } = await showConfirm(message)

                if (isConfirmed) {
                    await this.loginAsDealer(id)
                }
            },
            setCurrentFile(file) {
                this.currentFile = file
            },
            clearCurrentFile() {
                this.currentFile = null
            },
            async downloadDealerBalance(date) {
                date = date.toISOString().split('T')[0]
                const response = await api.dealers.downloadDealerBalance(date)

                let fileURL = window.URL.createObjectURL(
                    new Blob([response.data])
                )
                let fileLink = document.createElement('a')

                fileLink.href = fileURL
                fileLink.setAttribute(
                    'download',
                    `Баланс дилеров на ${date}.xlsx`
                )
                document.body.appendChild(fileLink)

                fileLink.click()
            },
        },
    }
</script>

<style scoped></style>
