<template>
  <v-dialog width="800" id="product-image-picker-modal" class="product-attachment-modal" :value="showDialog">
    <v-card>
      <v-card-title class="headline">
        <span><i class="fa fa-file-o"></i> Загрузка файла с информацией о начислении бонусов</span>
      </v-card-title>

      <v-card-text v-if="!isLoad">
        <form class="m-form m-form--state">
          <div>
            <p> Выберите файл</p>
            <input @change="fileUpload" type="file"
                   accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"/>
          </div>
        </form>
      </v-card-text>
      <div v-if="isLoad"  class="mb-16">
        <div id="circle2"></div>
      </div>
      <v-card-text>
        <span style="color: red;">*При большом количестве строк возможна долгая загрузка файла с бонусами.</span>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <CButton class="mr-3" color="secondary" @click="$emit('close')">Отмена</CButton>
        <CButton :disabled="!file || submitting" @click="submit" color="success"><i class="fa fa-save"></i> <span>Загрузить</span>
        </CButton>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import {serialize} from 'object-to-formdata';
import api from "@/api";
import {showMessage} from "@/lib/toasted";

export default {
  name: "upload-bonus-file-modal",
  props: {
    showDialog: {
      type: Boolean,
      required: true
    }
  },
  data() {
    return {
      file: null,
      submitting: false,
      isLoad: false,
    }
  },
  methods: {
    fileUpload(e) {
      let files = e.target.files || e.dataTransfer.files;

      if (files.length) {
        this.file = files[0];
      }
    },
    async submit() {
      const file = this.file;
      if (file) {

        try {

          this.isLoad = true
          this.submitting = true;

          const {data: {status, message}} = await api.dealers.uploadBonus(serialize({
            file
          }));

          showMessage(status, message);

          if (status === 'ok') {
            this.$emit('stored');
          }

          this.isLoad = false
        } finally {
          this.submitting = false;
        }
      }
    }
  }
}
</script>

<style scoped>

#circle2 {
  display: block;
  position: absolute;
  top: 40%;
  left: 50%;
  height: 50px;
  width: 50px;
  margin: -25px 0 0 -25px;
  border: 4px rgba(0, 0, 0, 0.25) solid;
  border-top: 4px rgba(255, 28, 28, 1) solid;
  border-radius: 50%;
  -webkit-animation: spin2 1s infinite linear;
  animation: spin2 1s infinite linear;
}

@-webkit-keyframes spin2 {
  from {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(359deg);
    transform: rotate(359deg);
  }
}
@keyframes spin2 {
  from {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(359deg);
    transform: rotate(359deg);
    -webkit-transform: rotate(359deg);
    transform: rotate(359deg);
  }
}

</style>
