<template>
    <ValidationObserver slim v-slot="{ invalid, untouched, handleSubmit }">
        <v-dialog :value="showDialog" :persistent="false" width="600" @input="closeDialog">
            <v-card>
                <v-card-title class="headline">
                    <span><i class="fa fa-users"></i> {{ currentItem ? 'Редактирование дилера' : 'Добавление дилера' }}</span>
                </v-card-title>

                <v-card-text>
                    <form>
                        <v-layout column>
                            <ValidationProvider name="name" rules="required" v-slot="{invalid, validated}">
                                <v-text-field :error="invalid && validated" label="Наименование *" hide-details="auto" v-model="name"></v-text-field>
                            </ValidationProvider>

                            <ValidationProvider name="email" rules="required|email" v-slot="{invalid, validated}">
                                <v-text-field :error="invalid && validated" label="E-mail *" hide-details="auto" v-model="email" type="email"></v-text-field>
                            </ValidationProvider>

                            <ValidationProvider name="unique_id" rules="required|integer" v-slot="{invalid, validated}">
                                <v-text-field :error="invalid && validated" label="КССС *" hide-details="auto" v-model="unique_id"></v-text-field>
                            </ValidationProvider>

                            <ValidationProvider name="contact_person_name" rules="required" v-slot="{invalid, validated}">
                                <v-text-field :error="invalid && validated" label="Имя контактного лица *" hide-details="auto" v-model="contact_person_name"></v-text-field>
                            </ValidationProvider>


                            <ValidationProvider name="contact_person_phone" rules="required" v-slot="{invalid, validated}">
                                <v-text-field :error="invalid && validated" label="Телефон контактного лица *" hide-details="auto" v-model="contact_person_phone"></v-text-field>
                            </ValidationProvider>

                            <ValidationProvider name="contact_person_position" rules="required" v-slot="{invalid, validated}">
                                <v-text-field :error="invalid && validated" label="Должность контактного лица *" hide-details="auto" v-model="contact_person_position"></v-text-field>
                            </ValidationProvider>


                            <ValidationProvider rules="integer" name="contract_agency_number" v-slot="{invalid, validated}">
                                <v-row>
                                    <v-col cols="12">
                                        <v-text-field :error="invalid && validated" label="Номер договора с агентством" hide-details="auto" v-model="contract_agency_number"></v-text-field>
                                    </v-col>
                                </v-row>
                            </ValidationProvider>

                            <v-container>
                                <v-row>
                                    <v-col cols="12" class="pb-0">
                                        <span class="contract-date-label">Дата договора с агентством</span>
                                    </v-col>
                                    <v-col cols="12" class="pt-0">
                                        <datepicker :default-show="true" v-model="contract_agency_date" :calendar-type="'infinite'" :format="'dd.MM.yyyy'"></datepicker>
                                    </v-col>
                                </v-row>
                            </v-container>

                            <v-row>
                                <v-col class="pt-0" cols="12">
                                    <v-row class="mt-0" v-for="(address, key) in delivery_addresses" :key="key">
                                        <v-col cols="10">
                                            <v-text-field placeholder="Адрес доставки" v-model="address.value" :key="key" hide-details="auto"></v-text-field>
                                        </v-col>
                                        <v-col class="d-flex align-end pl-0" cols="2">
                                            <CButton @click.prevent="removeAddress(key)" class="" color="danger">
                                                <i class="fa fa-close"></i>
                                            </CButton>
                                        </v-col>
                                    </v-row>
                                </v-col>

                                <v-col cols="12">
                                    <CButton @click="addAddress()" color="success">
                                        <i class="mr-2 fa fa-plus"></i> <span>Добавить адрес</span>
                                    </CButton>
                                </v-col>
                            </v-row>

                        </v-layout>
                    </form>

                </v-card-text>

                <v-divider/>

                <v-card-actions>
                    <v-spacer/>
                    <CButton class="mr-3" color="secondary" @click="$emit('close')">Отмена</CButton>
                    <CButton :disabled="invalid || !deliveryAddressFilled" @click="handleSubmit(submit)" color="success">
                        <i class="mr-2 fa fa-save"></i> <span>Сохранить</span>
                    </CButton>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </ValidationObserver>
</template>

<script>
import {ValidationProvider, ValidationObserver} from 'vee-validate';
import {DatePicker} from '@progress/kendo-vue-dateinputs';
import EntityModalMixin from "@/views/grid_elements/mixins/EntityModalMixin";
import api from "@/api";

export default {
    name: "dealer-create-update-modal",
    components: {
        ValidationProvider,
        ValidationObserver,
        'datepicker': DatePicker
    },
    mixins: [EntityModalMixin],
    data() {
        let state = {
            name: '',
            email: '',
            unique_id: null,
            contact_person_name: '',
            contact_person_phone: '',
            contact_person_position: '',
            contract_agency_number: '',
            contract_agency_date: new Date(),
            delivery_addresses: [{value: ''}]
        };

        if (this.currentItem) {
            state = {
                ...state,
                ...this.currentItem
            }

            if (this.currentItem.delivery_address) {
                state.delivery_addresses = this.currentItem.delivery_address;
            }

            if (this.currentItem.contract_agency_date) {
                state.contract_agency_date = new Date(this.currentItem.contract_agency_date);
            }
        }

        return state;
    },
    computed: {
        data() {
            return {
                name: this.name,
                email: this.email,
                unique_id: this.unique_id,
                contact_person_name: this.contact_person_name,
                contact_person_phone: this.contact_person_phone,
                contact_person_position: this.contact_person_position,
                contract_agency_number: this.contract_agency_number,
                contract_agency_date: this.contract_agency_date,
                delivery_addresses: this.delivery_addresses,
            }
        },
        deliveryAddressFilled() {
          return this.delivery_addresses.length > 0 && this.delivery_addresses[0].value;
        }
    },
    methods: {
        createEntity: api.dealers.createDealer,
        updateEntity: api.dealers.updateDealer,
        addAddress() {
            this.delivery_addresses = [...this.delivery_addresses, {value: ''}];
        },
        removeAddress(index) {
            this.delivery_addresses.splice(index, 1);
            this.delivery_addresses = [...this.delivery_addresses];
        },
    }
}
</script>

<style lang="css" scoped>
.contract-date-label {
    color: rgba(0, 0, 0, .87);
    font-size: 14px;
}
</style>
